<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ Object.entries(this.data).length === 0 ? "เพิ่ม" : "แก้ไข" }} ข้อมูล</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-device--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">
        <!-- NAME -->
        <vs-input label="MAC Address" v-model="address" class="mt-5 w-full" name="item-address" v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has('item-address')">{{ errors.first('item-address') }}</span>
        <!-- SIZE -->
        <vs-input label="Size" v-model="size" class="mt-5 w-full" name="item-size" v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has('item-size')">{{ errors.first('item-size') }}</span>

      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">ตกลง</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">ยกเลิก</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'

  export default {
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        },
      }
    },
    components: {
      VuePerfectScrollbar,
    },
    data() {
      return {
        dataId: null,
        size: "",
        address: "",
        settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
        },
      }
    },
    watch: {
      isSidebarActive(val) {
        if (!val) return;
        if (Object.entries(this.data).length === 0) {
          this.initValues();
          this.$validator.reset()
        } else {
          let {address, size, id} = JSON.parse(JSON.stringify(this.data));
          this.dataId = id;
          this.size = size;
          this.address = address;
          this.initValues()
        }
        // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
      }
    },
    computed: {
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive
        },
        set(val) {
          if (!val) {
            this.$emit('closeSidebar')
            // this.$validator.reset()
            // this.initValues()
          }
        }
      },
      isFormValid() {
        return !this.errors.any() && this.address
      },
      scrollbarTag() {
        return this.$store.getters.scrollbarTag
      }
    },
    methods: {
      initValues() {
        if (this.data.id) return;
        this.dataId = null;
        this.size = ""
        this.A01 = ""
        this.address = ""
      },
      submitData() {
        this.$validator.validateAll().then(result => {
          if (result) {
            const obj = {
              id: this.dataId,
              size: this.size,
              address: this.address
            };
            if (obj.id !== null) {
              this.$store.dispatch("device/updateItem", obj)
                .then(() => {
                  this.$store.dispatch("device/fetchDataListItems")
                })
            } else {
              delete obj.id;
              this.$store.dispatch("device/addItem", obj).then(() => {
                this.$store.dispatch("device/fetchDataListItems")
              })
            }

            this.$emit('closeSidebar');
            this.initValues()
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .add-new-data-sidebar {
    ::v-deep .vs-sidebar--background {
      z-index: 52010;
    }

    ::v-deep .vs-sidebar {
      z-index: 52010;
      width: 400px;
      max-width: 90vw;

      .img-upload {
        margin-top: 2rem;

        .con-img-upload {
          padding: 0;
        }

        .con-input-upload {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .scroll-device--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
  }
</style>
